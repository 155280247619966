.box {
    width: 450px;
    margin-bottom: 24px;
    margin-right: 300px;
}

@media only screen and (max-width : 490px) {
    .box {
        width: -webkit-fill-available;
        margin-left: 20px;
        margin-right: 20px;
    }
}