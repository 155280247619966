@media screen and (min-width: 600px) {
    [theme=dark] {
        --app-bg: #262c38;
        --card-color: #3a4456;
        --card-high-contrast: #d3d3d3;
        --card-high-contrast-alpha: #d3d5dc80;
        --card-high-contrast-superalpha: #d3d5dc10;
        --card-high-contrast-highlighted: #fff;
        --card-low-contrast: #95bfcc;
        --card-low-contrast-10: #81ccd11a;
        --card-low-contrast-darken: #3a9aa0;
        --card-color-in-stroke: #363d4a;
        --card-shadow: #00000059;
        --card-dented: #343a45;
        --card-raised: #3f4756;
        --card-raised-shadow: #0000008c;
        --card-bg-gradient: linear-gradient(90deg, #363d4a, #363d4aee 33%, #363d4a33);
        --card-loader-gradient: linear-gradient(-35deg, #d3d5dc, #d3d5dc73, #d3d5dc);
        --dented-stroke: linear-gradient(178deg, #00000059 22%, #3131314d, 46%, #ffffff1a 73%);
        --board-glass: #00000099;
        --board-glass-norgba: #1a1a1a;
        --board-column-color: #fff;
        --board-column-shadow: #000000cc;
        --header-glass: #000000b3;
        --dropdown-hover: #d3d5dc1a;
        --button-fill: linear-gradient(180deg, #4d5d7b, #48505f 30%, #3a4352);
        --button-fill-active: linear-gradient(0deg, #4d5d7b, #48505f 30%, #3a4352);
        --header-button-fill: linear-gradient(180deg, #4d5d7b, #48505f 30%, #3a4352);
        --header-button-fill-active: linear-gradient(-10deg, #1b1b1bcb, #000);
        --button-fill-hover: linear-gradient(180deg, #909aad, #4c525f);
        --button-shadow: #00000056;
        --button-text: #b4bbcb;
        --button-disabled: #ffffff99;
        --red-button-fill: linear-gradient(180deg, #ffa589, #f18038 24%, #b8681e);
        --red-button-fill-active: linear-gradient(0deg, #ffa589, #f18038 24%, #b8681e);
        --red-button-fill-hover: linear-gradient(180deg, #ffaf96, #c56648);
        --red-button-stroke: linear-gradient(170deg, #ffe9dc, #794100);
        --red-button-stroke-active: linear-gradient(-10deg, #ffe9dc, #794100);
        --red-button-text: #410909;
        --spinner-circle: #ffffff33;
        --spinner-segment: #fff;
        --dented-shadow: #22262f;
        --active-text: #29303d;
        --active-high-contrast: #ff965b;
        --disabled-badge: #bcc4d1;
        --extra-contrast: #000;
        --white-text: #e5e5e5;
    }
}

/* Customize the toolbar */
	.ptro-bar{
		height: 80px !important;
	}

	.ptro-icon-btn{
		width: 64px !important;
		height: 64px !important;
	}
	.ptro-icon{
		font-size: 28px !important
	}
	.ptro-tool-ctl-name{
		margin-left: 16px !important;
		padding-left: 10px !important;
		padding-right: 10px !important;
		font-size: 22px !important;
		color: #95bfcc !important;
	}
	.ptro-named-btn{
		height: 64px !important;
		font-size: 24px !important;
	}
	.ptro-info{
		font-size: 16px !important;
		color: #95bfcc !important;
	}
	.ptro-center-tablecell{
		background-color: "#606876" !important;
	}
	#canvas{
		width:100% !important;
	}



	@media screen and (min-width: 300px) {
		.ptro-holder-wrapper[class] button[class]:not(.color-diwget-btn):not(.ptro-color-btn) {
			position: relative;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			background-clip: padding-box;
			border-radius: 8px;
			color: #b4bbcb !important;
		}
	}

	@media screen and (min-width: 300px) {
		.ptro-holder-wrapper[class] .ptro-color-main[class], .ptro-holder-wrapper[class] {
			background-color: #3a4456 !important;
		}
	}

	@media screen and (min-width: 600px) {
		.ptro-holder-wrapper[class] .ptro-color-active-control[class] {
			cursor: default;
			background: linear-gradient(0deg, #4d5d7b, #48505f 30%, #3a4352) !important;
			-webkit-box-shadow: none;
			box-shadow: none;
		}
	}
	@media screen and (min-width: 300px) {
		.ptro-holder-wrapper[class] .ptro-icon-btn {
			height: 34px;
			width: 34px;
		}
	}
	.ptro-icon-btn {
		margin: 0 0 0 11px !important;
	}
	.ptro-icon-btn {
		border: 0;
		cursor: pointer;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@media screen and (min-width: 300px) {
		.ptro-holder-wrapper[class] button[class]:not(.color-diwget-btn):not(.ptro-color-btn):not(.ptro-color-active-control) {
			background: linear-gradient(180deg, #4d5d7b, #48505f 30%, #3a4352) !important;
			-webkit-box-shadow: 1px 1px 5px  #00000056;
			box-shadow: 1px 1px 5px #00000056;;
		}
	}

	.ptro-wrapper{
		bottom: 80px !important;
		background-color: black !important;
	}