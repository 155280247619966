#chatBoxContainer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    height: 80vh;
    z-index: 1;
    pointer-events: none;
}

#chatBoxContainer > * {
    pointer-events: all;
}

#chatBox {
    flex: auto;
    width: 100%;
	border: 1px solid grey;
	border-radius: 3px;
	overflow-y: scroll;
    background: rgba(255, 255, 255, 0.75);
}

#chatBox p {
	margin: 4px;
}