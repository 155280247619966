.video {
    position: relative;
    width: 100%;
    background: black;
    overflow: hidden;
}

#footer {
    position: fixed;
    background: #343437a3;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    z-index: 2;
    margin: 0;
    padding-top: 10px;
}
button{
    font-size: 20px !important;
}
.btn-text{
    margin-right: 15px !important;
}

@media only screen and (max-width : 490px) {
    #footer {
        height: 135px;
    }
    #endcall{
        margin-left: "10em" !important;
    }
}