@charset "utf-8";
/* @import "../node_modules/bulma/bulma.sass"; */

.dropdown-menu {
	left: auto;
	min-width: 0;
}

a.dropdown-item {
	padding-right: 1rem;
}

#image-viewer-header {
	align-items: inherit;
	margin: 0;
}

svg {
    pointer-events: none;
}

::-webkit-scrollbar {
    display: none;
}

.ptro-holder-wrapper {
	z-index: 9999;
}

.navbar-item img {
	height: 36px;
	max-height: none;
}

/* PainterroCustomStyles.css */

